import React, { Component} from 'react'
import {Link, navigate} from 'gatsby'
import Seo from '../components/SEO'
import Layout from '../components/Layout'
import {
	Container,
	Grid,
	Header,
	Loader,
	Message,
	Pagination

} from 'semantic-ui-react'
import ProductList from '../components/ProductList'
//import SubCategoryList from '../components/SubCategoryList'
import * as styles from './styles/pages.module.css'
//import Carousel from '../components/Carousel'
import {
	mobileMaxWidth,
	resizeTimer,
	tabletMaxWidth
} from '../globalVar'

const Negosyo = require('../../lib/negosyo')

export default class Search extends Component {
	state = {
		rating : undefined,  //not sure why this is in state, not used so safe to delete
		supplier: undefined, //not sure why this is in state, not used so safe to delete
		products: undefined,
		search: undefined,
		masterProducts: undefined,
		shown:5,
		mobile:undefined,
		limit:20,
		currentPage:1,
		paginationRange:4,
		loadingProducts:true,
		timeOut1:undefined
	};


	componentDidMount() {
		this.fetchData();
		this.handler();

		window.addEventListener('resize', this.resizeTimerFunc);
	}

	componentWillUnmount() {
		clearTimeout(this.state.timeOut1);
		window.removeEventListener('resize', this.resizeTimerFunc);

	}

	resizeTimerFunc = () => {
		clearTimeout(this.state.timeOut1);
		const temp = setTimeout(this.handler, resizeTimer);
		this.setState({
			timeOut1:temp
		})
	}

	//for changing products shown
	componentDidUpdate(prevProps, prevState) {
		const {location} = this.props
		const {currentPage, search} = this.state
		if(location.search!==prevProps.location.search) {
			const parameters = location.search.split("&");
			let searchTemp=undefined;
			let tempPage = undefined;
			let pageBool = false;
			for( let i = 0; i<parameters.length;i++) {
				if( parameters[i].split("=")[0]==="?search_query" || parameters[i].split("=")[0]==="search_query" ) {
					if(searchTemp===undefined && search!==decodeURIComponent( parameters[i].split("=")[1] ) ) {
						this.setState({search:decodeURIComponent( parameters[i].split("=")[1] ), loadingProducts:true})
						searchTemp=decodeURIComponent( parameters[i].split("=")[1] );
					}
				}else if (parameters[i].split("=")[0]==="?page" || parameters[i].split("=")[0]==="page") {
					if( currentPage!==parameters[i].split("=")[1] && tempPage===undefined) {
						this.setState({currentPage:parameters[i].split("=")[1]})
						tempPage = parameters[i].split("=")[1]
					}
					if( !isNaN(parameters[i].split("=")[1]) ) {
						pageBool = true;
					}
				}
			}
			if(pageBool===false && currentPage!==1) {
				this.setState({currentPage:1})
			}
			if(searchTemp!==undefined) {
				Negosyo.getProductSearchText(searchTemp).then((response)=> {
					if(Array.isArray(response) ) {
						this.setState({
							loadingProducts:false,
							masterProducts:response,
							products:response,
						})
					}else {
						//this assumes the response is an error if the response is not an array
						this.setState({
							loadingProducts:false,
							masterProducts:[],
							products:[],
						})

					}
				}).catch((err)=> {
					this.setState({loadingProducts:false})

				})
			}else {
				if(search===undefined) {
					navigate("/")
				}

			}
		}
	}

	handler = () => {
		//computer

		if (window.innerWidth > tabletMaxWidth ) {

			this.setState({
				shown:5,
				mobile:false,
				paginationRange:4
			})
		} //tablet
		else if (window.innerWidth > mobileMaxWidth ) {
			this.setState({
				shown : 5,
				mobile:true,
				paginationRange:2
			})
		} else {  //mobile
			this.setState({
				shown : 2,
				mobile:true,
				paginationRange:1
			})
		}

	}


	fetchData = () => {
		if(this.props.location.search) {
			const parameters = this.props.location.search.split("&");
			let search = undefined;
			let page = undefined;
			for( let i = 0; i<parameters.length;i++) {

				if( parameters[i].split("=")[0]==="?search_query" || parameters[i].split("=")[0]==="search_query" ) {
					if(search===undefined) {
						this.setState({search:decodeURIComponent( parameters[i].split("=")[1] )})
						search = decodeURIComponent( parameters[i].split("=")[1] )
					}
				}else if (parameters[i].split("=")[0]==="?page" || parameters[i].split("=")[0]==="page") {
					if(page===undefined) {
						this.setState({currentPage:parameters[i].split("=")[1]})
						page = parameters[i].split("=")[1]
					}
				}
			}
			if(search!==undefined) {
				Negosyo.getProductSearchText(search).then((response)=> {
					/*this.setState doesn't get batched within promises so this this.setState +
					 *this.setState above this if statement causes 2 this.setState rather than bathced into 1 big this.setState thus causing more render than necessary
					 */
					if(Array.isArray(response) ) {
						this.setState({
							loadingProducts:false,
							masterProducts:response,
							products:response,
						})
					}else {
						//this assumes it is an error if response is not an array
						this.setState({
							loadingProducts:false,
							masterProducts:[],
							products:[],
						})

					}

				}).catch((err)=> {
					this.setState({loadingProducts:false})
					//console.log("ERROR IN GETTING PRODUCT SEARCH")
					//console.log(err);
				})
			}else {
				//no search was added
				navigate("/")
			}
		}else {
			navigate("/")
		}
	}






	render() {
		const location = this.props.location
		const {
			products,
			shown,
			//mobile, not currently used so commmented it out"
			currentPage,
			limit,
			paginationRange,
			search,
			loadingProducts
		} =this.state

		let slicedProducts
		let length = 0
		let totalPages;
		if(products) {

			slicedProducts = products
			length = slicedProducts.length
			totalPages=Math.ceil(length/limit)
			slicedProducts = slicedProducts.slice( (currentPage-1)*limit, limit*currentPage)


		}
		let parameters = `search_query=${search}`
		let subHeader = ""
		if(loadingProducts) {
			subHeader = "Please standby ..."
		}else if(length) {
			subHeader = `${length} Products found`
		}


		return (
			<Layout location={location} backButton={true}>
				<Seo title={`search ${search}`} meta={[{name: `robots`, content: `noindex`}]} />
				<Container className={styles.container}>
					<Grid >
						<Grid.Column width={16}>
							<Header className={styles.superHeaderMod}>
								<h1>Search results for '{search}'</h1>
							</Header>
							{ !loadingProducts &&
								<div className={styles.italicText+" "+styles.centerText+" "+styles.msg}>
									Cannot find what you're looking for?&nbsp;
									<Link
										to={"/request-product/"}
										state={{
											productName:search
										}}
									>
										Let us know.
									</Link>
								</div>
							}
							{ !loadingProducts && slicedProducts ? (
								<React.Fragment>
									<div className={`${styles.centerText} ${styles.text}`}>
										{subHeader!=="" && (
											<React.Fragment>
												<br/>
												{subHeader}
												<br/>
												<br/>
											</React.Fragment>
										)}
									</div>
									{slicedProducts.length > 0 ? (
										<ProductList
											products={slicedProducts}
											shown={shown}
											grid={16}
											cameFrom={`search-${search}`}
											page={currentPage}
											offset={20}
											listName={`Search Results`}
										/>


									):(
										<Message negative className={`${styles.msg} ${styles.centerText}`}>
											<Message.Header>
												No Products found
											</Message.Header>
										</Message>
									)}
									{ totalPages > 1 && (
										<React.Fragment>
											<br/>
											<Pagination
												activePage={currentPage}
												onPageChange={(e, {activePage})=>{
													navigate(`${location.pathname}?${parameters}&page=${activePage}`)
												}}
												ellipsisItem={null}
												boundaryRange={null}
												siblingRange={paginationRange}
												totalPages={totalPages}
												key="Pagination"
												pageItem={{
													className:styles.item
												}}
												firstItem={{
													className:styles.item,
													content:"<<"
												}}
												lastItem={{
													className:styles.item,
													content:">>"
												}}
												nextItem={{
													className:styles.item,
													content:">"
												}}
												prevItem={{
													className:styles.item,
													content:"<"
												}}
											/>
										</React.Fragment>
									)}
								</React.Fragment>
							) : (
								<Loader active inline="centered" key={"loader"}/>
							)}

						</Grid.Column>
					</Grid>
				</Container>
			</Layout>
		)
	}
}



